<template>
  <div class="v-stack gap-3 h-stretch">
    <div class="pane-left gap-3">
      <label>Pricelist:</label>
      <select v-model="selectedPricelist">
        <option
          v-for="pricelist in basePricelists"
          :key="pricelist._id"
          :value="pricelist"
        >
          {{ pricelist.name }}
        </option>
      </select>
    </div>
    <TablePricelistItems
      v-if="selectedPricelist"
      :listItems="selectedPricelist.items"
      :selectable="true"
    ></TablePricelistItems>
    <div class="h-stack h-end">
      <button class="submit" @click="submit()">Import</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TablePricelistItems from "@/components/TablePricelistItems.vue";

export default {
  props: ["id"],
  components: {
    TablePricelistItems,
  },
  data() {
    return {
      currentPricelist: null,
      selectedPricelist: null,
      basePricelists: [],
    };
  },
  methods: {
    ...mapActions(["getPricelists", "updatePricelist"]),
    submit() {
      const items = this.selectedPricelist.items.filter(
        (item) => item.selected == true
      );

      for (const item of items) {
        delete item["selected"];
        item.item = item.item._id;
      }

      this.currentPricelist.items = this.currentPricelist.items.concat(items);

      this.updatePricelist(this.currentPricelist)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPricelists()
      .then((pricelists) => {
        for (const pricelist of pricelists) {
          if (pricelist._id == this.id) {
            this.currentPricelist = pricelist;
            break;
          }
        }

        this.basePricelists = pricelists.filter(
          (item) => item.base && item._id != this.id
        );

        for (const pricelist of this.basePricelists) {
          pricelist.items = pricelist.items.filter((item) => {
            for (const mainItem of this.currentPricelist.items) {
              if (mainItem.item._id == item.item._id) {
                return false;
              }
            }
            return true;
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
label {
  align-self: center;
}
</style>
